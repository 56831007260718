import React, { FC, useState, useMemo, useEffect, useCallback, useRef } from 'react';
import { graphql } from 'gatsby';
import algoliaSearch from 'algoliasearch/lite';
import { Layout } from 'layout';
import useScreenRecognition from 'gatsby-theme-husky/src/hooks/useScreenRecognition';
import Banner from 'gatsby-theme-husky/src/components/Banner';
import Button from 'gatsby-theme-husky/src/common/Button';
import SearchResultList from 'gatsby-theme-husky/src/components/SearchResultList';
import {
  SearchPageComponentProps,
  AlgoliaSearchResult,
} from 'gatsby-theme-husky/src/templates/SearchPage/models';
import { EXCEPTIONS_STRINGS, EXCEPTION_CATEGORY } from './constants';
import 'gatsby-theme-husky/src/templates/SearchPage/SearchPageMain.scss';
import 'gatsby-theme-husky/src/templates/SearchPage/SearchPageRTL.scss';

// eslint-disable-next-line prefer-destructuring
const GATSBY_ALGOLIA_APP_ID = process.env.GATSBY_ALGOLIA_APP_ID;
// eslint-disable-next-line prefer-destructuring
const GATSBY_ALGOLIA_SEARCH_KEY = process.env.GATSBY_ALGOLIA_SEARCH_KEY;
// eslint-disable-next-line prefer-destructuring
const GATSBY_ALGOLIA_SEARCH_QUERY_INDEX = process.env.GATSBY_ALGOLIA_SEARCH_QUERY_INDEX;

if (!GATSBY_ALGOLIA_APP_ID || !GATSBY_ALGOLIA_SEARCH_KEY || !GATSBY_ALGOLIA_SEARCH_QUERY_INDEX) {
  throw new Error('Looks like some of required ENV variables "GATSBY_ALGOLIA_*" are missing');
}

const algoliaClient = algoliaSearch(GATSBY_ALGOLIA_APP_ID, GATSBY_ALGOLIA_SEARCH_KEY);
const algoliaSearchIndex = algoliaClient.initIndex(GATSBY_ALGOLIA_SEARCH_QUERY_INDEX);

const SearchPage: FC<SearchPageComponentProps> = ({
  data: {
    umbracoHome: { disclaimerDesktopImage, disclaimerMobileImage },
    umbracoSearch: {
      seoMetaKeywords,
      seoMetaTitle,
      seoMetaDescription,
      seoExternalHreflangs,
      defaultCompositions,
      url,
      banner,
      resultsText,
      noResultsText,
      buttonText,
      buttonAriaLabel,
      searchResultsDesktopCount,
      searchResultsMobileCount,
    },
  },
  location,
}) => {
  const { isMobile } = useScreenRecognition();

  const [searchString, setSearchString] = useState('');
  const [searchPage, setSearchPage] = useState(1);
  const [searchResults, setSearchResults] = useState<AlgoliaSearchResult[]>([]);
  const [searchResultsCount, setSearchResultsCount] = useState<number | undefined>(undefined);
  const [isLoadMoreButton, setIsLoadMoreButton] = useState<boolean>(true);
  const [localCountry, setLocalCountry] = useState('');

  const searchResultsRef = useRef<HTMLUListElement>(null);

  const searchPageSize = useMemo(() => {
    return isMobile ? searchResultsMobileCount : searchResultsDesktopCount;
  }, [searchResultsDesktopCount, searchResultsMobileCount]);

  const fetchSearchResultPage = useMemo(
    () => async () => {
      const { hits, page, nbHits, nbPages } = await algoliaSearchIndex.search<AlgoliaSearchResult>(
        searchString,
        {
          page: searchPage - 1,
          hitsPerPage: searchPageSize,
          filters: `lang:"${defaultCompositions.siteSettings.lang}"`,
        }
      );

      setSearchPage(searchPage + 1);
      setSearchResults([
        ...searchResults,
        ...hits?.map(({ description, ...restHit }) => ({
          ...restHit,
          description: restHit._snippetResult?.description.value || description,
        })),
      ]);
      setSearchResultsCount(nbHits);
      setIsLoadMoreButton(page + 1 < nbPages);
    },
    [searchString, searchPage, searchPageSize, searchResults]
  );

  const loadMore = useCallback(() => {
    fetchSearchResultPage();

    function resetFocusToFirstElementOfNewSearchResultPage(e: KeyboardEvent) {
      if (e.key === 'tab' || e.keyCode === 9) {
        const searchResultsListElement = searchResultsRef.current;

        if (searchResultsListElement) {
          const firstOfNewResultsIndex = (searchPage - 1) * searchPageSize - 1;
          const firstOfNewResults = searchResultsListElement.children[firstOfNewResultsIndex];
          const firstOfNewResultsLink = firstOfNewResults.getElementsByTagName('a')[0];

          if (firstOfNewResultsLink) {
            firstOfNewResultsLink.focus();
          }
        }

        window.removeEventListener('keydown', resetFocusToFirstElementOfNewSearchResultPage);
      }
    }

    window.addEventListener('keydown', resetFocusToFirstElementOfNewSearchResultPage);
  }, [searchPage, searchPageSize, fetchSearchResultPage]);

  useEffect(() => {
    const INITIAL_STATE = '/pe/';
    const storageCountry = localStorage.getItem('country') || INITIAL_STATE;
    setLocalCountry(storageCountry);
    const queryParams = new URLSearchParams(document.location.search.substring(1));
    const qQueryParam = queryParams.get('q') || '';
    const pQueryParam = queryParams.get('p') || '1';

    if (qQueryParam !== searchString) {
      setSearchString(qQueryParam);
      setSearchPage(parseInt(pQueryParam, 10));
      setSearchResults([]);
      setSearchResultsCount(undefined);
      setIsLoadMoreButton(true);
    }
  }, [location, searchString]);

  useEffect(() => {
    if (searchString !== '') {
      fetchSearchResultPage();
    }
  }, [searchString]);

  const currentCountryUrls: any[] = [];
  const finder = EXCEPTIONS_STRINGS.find((i) => i === searchString);
  if (finder) {
    searchResults.forEach((i) => {
      const auxUrls = i.url.split('/');
      auxUrls.forEach((w) => {
        if (w === finder) {
          currentCountryUrls.push(i);
        }
      });
    });
  }
  searchResults.forEach((i) => {
    const auxUrls = i.url.split('/');
    auxUrls.forEach((w) => {
      if (w === localCountry.replace(/[^a-zA-Z0-9 ]/g, '') || w === EXCEPTION_CATEGORY) {
        currentCountryUrls.push(i);
      }
    });
  });

  return (
    <Layout
      {...{ defaultCompositions, disclaimerDesktopImage, disclaimerMobileImage }}
      seo={{
        seoMetaKeywords,
        seoMetaTitle,
        seoMetaDescription,
        seoExternalHreflangs,
        isSearchPage: true,
      }}
      className="search-page"
    >
      {banner.map(({ properties: { localImage, title, ...restBannerProperties } }) => (
        <Banner
          url={url}
          key={title}
          title={title}
          className="search-page__banner"
          {...{ ...restBannerProperties, image: localImage }}
        />
      ))}
      <div className="search-page__wrapper">
        <h2 className="search-page__result-text">
          {typeof searchResultsCount === 'number'
            ? `${
                currentCountryUrls.length
                  ? `${currentCountryUrls.length} ${resultsText}`
                  : noResultsText
              } “${searchString}”`
            : null}
        </h2>
        <SearchResultList
          ref={searchResultsRef}
          className="search-page__search-result-list"
          searchResults={currentCountryUrls}
        />
        {isLoadMoreButton ? (
          <Button
            btnColorVariant="dark"
            ariaLabel={buttonAriaLabel}
            handler={loadMore}
            classes="search-page__button"
          >
            {buttonText}
          </Button>
        ) : null}
      </div>
    </Layout>
  );
};

export const query = graphql`
  query SearchPages($url: String = "", $lang: String) {
    umbracoSearch: umbracoSearch(url: { eq: $url }, lang: { eq: $lang }) {
      url
      seoMetaTitle
      seoMetaDescription
      seoMetaKeywords
      seoExternalHreflangs {
        hreflang: key
        href: value
      }
      defaultCompositions {
        ...DefaultCompositionsFragment
      }
      banner {
        properties {
          title
          variant
          localImage {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          imageAlt
          backgroundColour
        }
      }
      resultsText
      noResultsText
      buttonText
      buttonAriaLabel
      searchResultsDesktopCount
      searchResultsMobileCount
    }
    umbracoHome(lang: { eq: $lang }) {
      disclaimerMobileImage {
        ...FragmentImageWithAlt
      }
      disclaimerDesktopImage {
        ...FragmentImageWithAlt
      }
    }
  }
`;

export default SearchPage;
